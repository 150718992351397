// import { renderField } from 'components/TypographyUtils';
import { greenDarkPh, redDarkPh, yellowDarkPh } from 'controlTower/chartUtils/colors';
import React, { memo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { format0 } from 'utils/utils';

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  rotation: -0.5 * Math.PI,
  cutoutPercentage: 85,
  legend: {
    position: 'bottom',
    display: false,
  },
  plugins: {
    datalabels: {
      color: ['rgb(0,0,0,0.87)', '#000'],
      font: {
        weight: 400,
      },
      display: false,
    },
  },
};
function getChartData(props) {
  const { label, value, total, cutOffs } = props;
  return {
    labels: [label + (value && total > 0 ? ` (${format0((100 * value) / total)}%)` : '')],
    datasets: [
      {
        data: [value, total - value],
        backgroundColor: [
          ['DGP', 'QDGP'].includes(label) && props?.colorCode !== 'none'
            ? props.colorCode
            : value / total >= cutOffs?.[0]
            ? greenDarkPh
            : value / total > cutOffs?.[1]
            ? yellowDarkPh
            : redDarkPh,
          'transparent',
        ],
      },
    ],
  };
}

function MeterChart(props) {
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = 1.25;
        ctx.font = fontSize + 'em sans-serif';
        ctx.textBaseline = 'top';
        ctx.fillStyle = chart.config.data.datasets?.[0]?.backgroundColor[0];

        var text = `${format0(
            (100 * chart.config.data.datasets?.[0]?.data[0]) /
              (chart.config.data.datasets?.[0]?.data[0] + chart.config.data.datasets?.[0]?.data[1])
          )}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (21 * height) / 50;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  return <Doughnut data={getChartData(props)} options={chartOptions} plugins={plugins} />;
}

export default memo(MeterChart);
